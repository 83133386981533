import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import {
  Grid,
  Link,
  Divider,
  Container,
  Typography,
  Stack,
} from "@mui/material";
// routes
import { PATH_AUTH, PATH_PAGE } from "../../routes/paths";
// components
import SocialsButton from "../../components/SocialsButton";
import LogoReverseFull from "src/components/LogoReverseFull";

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: "About",
    children: [
      // { name: "About us", href: "#" },
      { name: "Returns and refund policy", href: PATH_PAGE.returnPolicy },
      { name: "Terms and Conditions", href: PATH_PAGE.termsConditions },
      { name: "Privacy Policy", href: PATH_PAGE.privacyPolicy },
    ],
  },
  {
    headline: "My Account",
    children: [
      { name: "Login", href: PATH_AUTH.login },
      { name: "Order History", href: PATH_PAGE.client.myOrders },
      // { name: "Refund History", href: "#" },
      // { name: "Address Book", href: "#" },
      { name: "Cart", href: "#" },
      { name: "Wish List", href: "#" },
    ],
  },
  {
    headline: "Customer Support",
    children: [
      { name: "wecare@yourbasket.co.ke", href: "#" },
      { name: "+254 743 022 022", href: "#" },
    ],
  },
];

const RootStyle = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: theme.palette.primary.dark,
  color: "white",
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <RootStyle>
      <Divider />

      <Container sx={{ pt: 4 }}>
        <Grid
          container
          justifyContent={{ xs: "center", md: "space-between" }}
          sx={{ textAlign: { xs: "center", md: "left" } }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <LogoReverseFull sx={{ mx: { xs: "auto", md: "inherit" } }} />
          </Grid>

          <Grid item xs={8} md={3}>
            <Typography variant="h6" sx={{ pr: { md: 5 } }}>
              Make Money with YourBasket
            </Typography>

            <Link
              to={PATH_AUTH.registerVendor}
              color="inherit"
              variant="body2"
              component={RouterLink}
              sx={{ display: "block" }}
            >
              Become a Vendor
            </Link>

            <Stack
              direction="row"
              justifyContent={{ xs: "center", md: "flex-start" }}
              sx={{ mt: 5, mb: { xs: 5, md: 0 } }}
            >
              <SocialsButton sx={{ mx: 0.5, color: "secondary.main" }} />
            </Stack>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack
              spacing={5}
              direction={{ xs: "column", md: "row" }}
              justifyContent="space-between"
            >
              {LINKS.map((list) => (
                <Stack key={list.headline} spacing={2}>
                  <Typography component="p" variant="overline" color={'secondary.main'}>
                    {list.headline}
                  </Typography>

                  {list.children.map((link) => (
                    <Link
                      to={link.href}
                      key={link.name}
                      color="inherit"
                      variant="body2"
                      component={RouterLink}
                      sx={{ display: "block" }}
                    >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: "center", md: "left" },
          }}
        >
          © 2024. All rights reserved
        </Typography>
      </Container>
    </RootStyle>
  );
}
