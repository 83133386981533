import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Stack, Typography } from '@mui/material';

// routes
// import { PATH_PAGE } from '../../../routes/paths';
// components
import Iconify from '../../../components/Iconify';
import { PATH_PAGE } from 'src/routes/paths';

// ----------------------------------------------------------------------

const RootStyle = styled(RouterLink)(({ theme }) => ({
    zIndex: 999,
    right: 0,
    display: 'flex',
    cursor: 'pointer',
    textDecoration: 'none',
    // position: 'fixed',
    alignItems: 'center',
    // top: theme.spacing(16),
    // height: theme.spacing(5),
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
    // paddingTop: theme.spacing(1.25),
    // boxShadow: theme.customShadows.z20,
    // color: theme.palette.text.primary,
    // backgroundColor: theme.palette.background.paper,
    borderTopLeftRadius: Number(theme.shape.borderRadius) * 2,
    borderBottomLeftRadius: Number(theme.shape.borderRadius) * 2,
    transition: theme.transitions.create('opacity'),
    '&:hover': { opacity: 0.72, backgroundColor: 'primary.main' },
}));

// ----------------------------------------------------------------------

export default function WishlistWidget() {
    //   const { checkout } = useSelector((state) => state.product);
    //   const totalItems = sum(checkout.cart.map((item) => item.quantity));

    return (
        <RootStyle to={PATH_PAGE.eCommerce.wishlist}>
            {/* <Badge showZero badgeContent={totalItems} color="error" max={99}> */}

            <Stack direction="row" spacing={1} sx={{mx:1, alignItems:'center'}}>
                <Iconify icon={'eva:heart-outline'} width={24} height={24} 
                    sx={{ color: "secondary.light" }}
                    />
                <Stack>
                <Typography color='secondary.light' sx={{fontSize: 14}}>My</Typography>
                <Typography color='secondary.light' sx={{fontSize: 14, fontWeight: 700}}>
                    Wishlist
                </Typography>
                </Stack>
            </Stack>

            {/* </Badge> */}
        </RootStyle>
    );
}
