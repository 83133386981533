import { useLocation, Outlet } from "react-router-dom";
// @mui
import { Box, Stack, styled } from "@mui/material";
// components
// import Logo from "../../components/Logo";
//
// import MainFooter from './MainFooter';
// import MainHeader from './MainHeader';
import ShopHeader from "./ShopHeader";
import MainFooter from "./MainFooter";

// ----------------------------------------------------------------------
const ContentStyle = styled("div")(({ theme }) => ({
  overflow: "hidden",
  position: "relative",
  // marginTop: 80,
  backgroundColor: theme.palette.grey[100],
}));
// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();

  const isHome = pathname === "/";

  return (
    <Stack sx={{ minHeight: 1 }}>
      {/* <MainHeader /> */}
      <ShopHeader />

      <ContentStyle sx={{paddingTop: isHome ? 8 : 14}}>
        <Outlet />
      </ContentStyle>

      <Box sx={{ flexGrow: 1 }} />


      <MainFooter />
    </Stack>
  );
}
